import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>No!</h1>
        <p>
          <a href="https://thegauntlet.ca/">&copy; The Gauntlet</a>
        </p>
      </header>
    </div>
  );
}

export default App;
